import david from '../ArtistsList/img/david.jpg'
import hebra from '../ArtistsList/img/hebra.jpg'
import mancha from '../ArtistsList/img/mancha.jpg'
import maniatics from '../ArtistsList/img/maniatics.jpg'
import vandermou from '../ArtistsList/img/vandermou.jpg'
import suspect from '../ArtistsList/img/suspect.jpg'
import impex from '../ArtistsList/img/impex.jpg'
import cidiah from '../ArtistsList/img/cidiah.jpg'
import badLegs from '../ArtistsList/img/badLegs.jpg'
import hackwaves from '../ArtistsList/img/hackwaves.jpg'
import moderate from '../ArtistsList/img/moderate.jpg'
import mv from '../ArtistsList/img/mv.jpg'
import sete from '../ArtistsList/img/sete.jpg'
import state from '../ArtistsList/img/state.jpg'
import superRush from '../ArtistsList/img/superRush.jpg'
import helium from '../ArtistsList/img/helium.jpg'

export const artistsData = {
  BadLegs: {
    name: 'BAD LEGS',
    image: badLegs,
    bio: 'BAD LEGS, productor y DJ de Breakbeat & Dnb de Paradas, Sevilla (España).\nHa compartido line up con estos Deejays:\nLady Waks, The Magnet Men, Freq Nasty, Backdraft, D-Ranged, Retchord, Deekline, Stanton Warriors, Sonz Of Mecha, DistortionZ, MIAU, Jay Cunning, Mutiny, Baobinga, Freestylers, Free Fall Collective, Anuschka, Pray For Bass, BreaksMafia, Shade K, TERRIE KYND, Aldo Ferrari, Kuplay, Aggresivnes, The Brainkiller, etc.\n Sus mejores temas, publicados por Funktasty Crew (Top 1 a nivel mundial en Beatport), Space pizza records, Elektroshok record, Nipponer, Guachinche, Empire Music Records, etc.\nEn Julio de 2024 lanza su primer LP de Neurofunk y Neurobreaks en Gutting Audio.\nY sí: usa silla de ruedas, porque sus PIERNAS están en MAL estado... ;)',
    socialLinks: {
      instagram: 'https://www.instagram.com/badlegs_el_peki96/ ',
      soundcloud: 'https://soundcloud.com/badlegs ',
    },
  },
  Cidiah: {
    name: 'Cidiah',
    image: cidiah,
    bio: 'Jesús Pascual, productor emergente con base en Sevilla, está influenciado por el rock y ha iniciado una nueva etapa creativa dentro del Drum & Bass. Desde joven, su pasión por la música lo llevó a explorar sonidos intensos y enérgicos, lo que finalmente lo guió hacia los terrenos más pesados y oscuros del género. Su estilo se define por una potente fusión entre la fuerza del rock y la contundencia del neurofunk y el hard dnb. En esta nueva etapa de su carrera, Jesús ya ha logrado firmar temas con sellos como Milleville Music y Bass Boost. Su visión es clara: establecerse como una figura destacada en la escena drum and bass, llevando su sonido único a nuevos públicos y escenarios. Con un enfoque dedicado en la producción, sigue empujando los límites del género hacia nuevos horizontes,siempre buscando evolucionar y perfeccionar su arte.',
    socialLinks: {
      instagram: 'https://www.instagram.com/cidiah13/ ',
      soundcloud: 'https://soundcloud.com/cidiah13',
    },
  },
  DavidSynth: {
    name: 'David Synth',
    image: david,
    bio: 'Productor y Dj sevillano que nace en el mundo del espectáculo , hijo de una actriz y un técnico. Comienza su desarrollo artístico a la edad de 11 años, obteniendo rápidamente un reconocimiento local produciendo beats, pinchando y mezclando a grupos que a día de hoy tiene un amplio reconocimiento tanto como en la escena underground como en la escena comercial.\n Cuenta con mas de 15 años de producciones, grabaciones, mezclas, sesiones y conciertos a sus espaldas, siendo a día de el especialista de mezcla y mastering de algunos artistas de primer nivel nacional. \n Con una notable influencia del Funk ,Jazz y  se caracteriza por la mezcla de estilos en sus sesiones, plenitud de armonías y ritmos sofisticados en sus producciones llevando el genero a su lado mas conceptual y tecnico, obteniendo un sonido característico y original en sus tracks, los cuales siempre destacan de forma notable en sus sets moviendo a la pista desde el primer hasta el ultimo minuto de sesión. Dichos tracks han sido pinchados por artistas de la envergadura de Misanthrop, Lockjaw ,Wreckless, BlackBarrel además de gran parte de la escena en España \n Cabe destacar que David Synth ha compartido cartel con una infinidad de artistas, véase: Emperor, InsideInfo, Billain, Skeptical, Jade, Audio, Fourward, June Miller, Imanu (aka Signal), Cause4Concern, Black Barrel, MeanTeeth y Hypoxia entre otros..',
    socialLinks: {
      instagram: 'https://www.instagram.com/_davidsynth/',
      soundcloud: 'https://soundcloud.com/d4vidsynth',
    },
  },
  Hackwaves: {
    name: 'Hackwaves',
    aka: 'Qkhack',
    image: hackwaves,
    bio: 'DJ/Productor de Neurofunk.Fernando Leal aka Hackwaves inició este nuevo proyecto en 2022. Influenciado por estilos más duros como Hard Dnb, Crossbreed/Hardcore, decidió transformar sus sonidos hacia un estilo más Neurofunk, con líneas de bajo más sintéticas y baterías potentes. Siempre vinculado a la escena, ha compartido cabina con grandes artistas internacionales (Limewax, Audio, Aeph, Agressor Bunx, June Miller, DJ Hidden, Technical Itch, Panacea, Counterstrike, Hallucinator, Cooh, Dub Elements, Impak, eDUB... ).',
    socialLinks: {
      instagram: 'https://www.instagram.com/hackwavesdnb/',
      soundcloud: 'https://soundcloud.com/hackwaves',
    },
  },
  Hebra: {
    name: 'Hebra',
    image: hebra,
    bio: 'Hebra es un artista sevillano, actualmente residente en Bristol, que en sus inicios, con tan sólo 16 años, comenzó como Dj elaborando sus primeras mezclas de Break Beat -estilo que marcaría y cambiaría su vida en todos los ámbitos.Más tarde, empezó a producir sus propias canciones como componente del grupo Master & Disaster. Sin embargo, no es hasta los 22 cuando decide empezar a introducirse en el mundo del Drum and Bass, con proyectos como Trivision o Hebra, el cual lleva 1 año y medio en marcha. Para él, la música siempre ha sido como un método de evasión y curación de males, además de una fuente de felicidad, y, aunque, nunca la consideró más que un mero hobby para su disfrute personal, se va acercando, cada vez más, a una prometedora carrera profesional. De este modo, su trabajo se ha visto recompensado por sellos como Boey Audio, Tesseract RRecord, Black monsta o T3K record.',
    socialLinks: {
      instagram: 'https://www.instagram.com/hebra_dnb/',
      soundcloud: 'https://soundcloud.com/hebradnb',
    },
  },
  Helium: {
    name: 'Helium',
    image: helium,
    bio: 'GTTN ARTIST BIO IN PROGRESS',
    socialLinks: {
      instagram: 'https://www.instagram.com/heliumdnb/?hl=es ',
      soundcloud: 'https://soundcloud.com/heliumdnb',
    },
  },
  Impex: {
    name: 'Impex',
    image: impex,
    bio: 'Los orígenes de Impex se remontan a ser bajista en bandas de Metal, Rock y Hardcore desde 2004. Aquí comienza su amor por tocar y crear música más dura. Como parte de la sección rítmica de los grupos, rápidamente desarrolló un gusto por los ritmos rotos y las líneas de bajo groovy. Después de encontrarse atrapado entre los otros caminos que los miembros de la banda querían seguir, decide ir por su cuenta. Después de asistir a sus primeras Therapy Sessions en Lisboa en 2009, decidió que el Drum n Bass era el camino a seguir. Con la ayuda de otros productores y amigos, de repente se encontró absolutamente sumergido en la producción musical y la experimentación con el diseño de sonido. Más que simplemente otra melodía, otra pista, otro sonido… es una nueva vibra, un nuevo enfoque y una expresión de sentimientos.',
    socialLinks: {
      instagram: 'https://www.instagram.com/marcos_impex/',
      soundcloud: 'https://soundcloud.com/impex_music',
    },
  },
  Mancha: {
    name: 'Mancha',
    image: mancha,
    bio: 'Dj y productor que empieza a ineresarse por la musica bass gracias a estilos como el 2Step o el break beat. Muy pronto se interesa mas en profundida por el break beat, estilo en el que incluso llegó a actuar en junto a artistas de la talla de Aquasky, Breakfastaz, Backdraft y Baobinga entre otros. Años mas tarde forma un grupo con cierta relevancia en la escena bass underground con cierta relevancia consiguiendo un programa semanal en la parrilla de Rinse Fm y actuando en salas como Razzmatazz juto a artistas como Dodge & Fuski, Dope Dod o Delta Heavy, contando con producciones editadas en discográficas como Play Me.A dia de hoy, totalmente volcado en el estilo Drum and Bass, cuenta con releases en la mítica discográfica Citrus, ompartiendo rooster con artistas como Noisia, Mefjus o Black Sun Empire entre otros. Su estilo personal y potente llena el escenario y sus producciones con sonidos oscuros y contundentes.',
    socialLinks: {
      instagram: 'https://www.instagram.com/kvne_dnb/',
      soundcloud: 'https://soundcloud.com/kvnednb',
    },
  },
  Maniatics: {
    name: 'Maniatics',
    image: maniatics,
    bio: 'Maniatics ésta formando por 3 amigos que les une su pasión por la música , sobre todo el Drum&Bass. Antonio Gordillo (Rubio) , Alvaro Alcazar y Francisco Javier Zarco (Javiviz) aficandos en Los Palacios y Villafranca (Sevilla) formaron Maniatics en 2012 ,desde entonces han editado música con varios sellos de la industria del Drum&bass (Melting Pot Records, Subculture Music, Titan Records, KosenProd , Eatbrain , Viper Recording , etc.). En sus directos predomina el Neurofunk , aunque les gusta poner durante sus sesiones otros estilos del Drum&bass para crear dinamismo en sus sesiones y no sean lineal.Han actuado en varias ciudades de España como Sevilla , Granada , Madrid ,Murcia ,etc, y entros países como Inglaterra , Portugal , Republica Checa , Austria ,etc.',
    socialLinks: {
      instagram: 'https://www.instagram.com/maniatics_official/',
      soundcloud: 'https://soundcloud.com/maniatics',
    },
  },
  ModerateHate: {
    name: 'Moderate Hate',
    image: moderate,
    bio: 'Moderate Hate (Sergio Fenández) nace de la evolución artística de las ganas de experimentar y crear tracks con nuevos ritmos tocando casi todos los subgéneros del Drum ‘n Bass . Carga sus sesiones buscando siempre los bajos mas contundentes, las baterías mas frenéticas y los tracks mas pisteros trasmitiendo gran energía en sus sets y producciones. En 2019 comienza a formar parte del equipo de BLACK MONSTA, en el cual ha ido editando varios EPs y a su vez perteneciendo al Booking del sello. Asimismo ha sacado a la venta otros trabajos en distintos sellos discográ□cos como son INHABIT RECORDS, MELTING POT RECORDS, HISTERIA RECORDS ,GHOST BASS LABEL, PARALLEL DEPTH, GUTTING AUDIO…. ',
    socialLinks: {
      instagram: 'https://www.instagram.com/moderatehate/?hl=es ',
      soundcloud: 'https://soundcloud.com/moderate-hate ',
    },
  },
  Mv: {
    name: 'Mv',
    image: mv,
    bio: 'MV es el primer proyecto en solitario de Moisés Valdivieso. Una propuesta enfocada a la producción de Drum and Bass, Neurofunk y otras formas de Bass music. “MV”, parte del sello Optikal Dubs Records, lanzó su primer EP titulado “Rise Up” el cual se encuentra disponible en tiendas digitales a nivel mundial. También produce eventos de música electrónica en la isla de Tenerife bajo el nombre "Bass Reunion", organizando fiestas con artistas como Pendulum, Crissy Criss, Benny Page, Chords, Zardonic, Brainkiller, Colombo, entre otros. Poco a poco, MV es uno de los nombres de la escena Neurofunk en España, organizando eventos con el colectivo 3Wind, Histeria Records y Tribe está constantemente en programas de radio como PlayRoom Studio para promocionar y llevar la escena DNB al siguiente nivel...',
    socialLinks: {
      instagram: 'https://www.instagram.com/bass_music_mv/',
      soundcloud: 'https://soundcloud.com/bassmusicmv',
    },
  },
  Sete: {
    name: 'Sete',
    image: sete,
    bio: 'Influenciado por el sonido del Neurofunk de artistas como Black Sun Empire, State of Mind o Phace, SETE comenzó a darse a conocer en la escena del Drum & Bass gracias al colectivo Bass Company Spain, el cual le dio la oportunidad de compartir cartel con lo mejor de la escena nacional e internacional y donde se le permitió dejar huella con su estilo personal y sus mezclas rápidas y contundentes a 4 platos cargadas del Neurofunk más duro que caracterizan todas las sesiones de este joven dj y productor Sevillano al que no puedes perderte!! ',
    socialLinks: {
      instagram: 'https://www.instagram.com/setednb/',
      soundcloud: 'https://soundcloud.com/setednb',
    },
  },
  StateOfDisorder: {
    name: 'State of Disorder',
    image: state,
    bio: 'Dj y productor Sevillano, caracterizado por fuertes y contundentes líneas de baterías enriquecida por otros géneros obteniendo sets muy fuertes y elegantes, entró en la escena de la mano del promotora The House Party , este artista siente la necesidad de cambiar la dinámica musical del Drum and Bass tanto en Sevilla como fuera de casa, apostando por pistas desconocidas y estilos menos sonados en Andalucía. en sus sesiones se nota la influencia de otros estilos musicales como el funky , soul, jazz, blues, rap, etc., creando conjuntos sonoros muy elaborados pero siempre respetando la fuerza y contundencia  de las cajas, para así mantener una estructura rolling personal y seria, escúchalo.',
    // 'BIO [ENG]',
    // 'Sevillian Dj and producer, characterized by strong and forceful drum lines enriched by other genres obtaining very strong and elegant sets, entered the scene of the hand of the promoters Jack The Ripper, this artist feels the need to change the drum & bass scene both in Seville as away from home, betting on unknown tracks and less popular styles in Andalusia. In his sessions you can see the influence of other musical styles such as soul, jazz, blues, rap, etc., creating a different sound set but always respecting the strength and forcefulness of the batteries, in order to maintain a personal and serious rolling structure, listen to it .',
    socialLinks: {
      instagram: 'https://www.instagram.com/stateofdisorder.dnb/',
      soundcloud: 'https://soundcloud.com/stateofdisorde',
    },
  },
  SuperRush: {
    name: 'Super Rush',
    image: superRush,
    bio: 'Super Rush, joven dj/productor andaluz actualmente dedicandose explícitamente a la produccion de DNB. Destacando sus sesiones al mas puro ritmo del Neurofunk con mezclas contundentes y absueltas por los ritmos "enfermos". Perteneciente al colectivo "Bass Invassion". Hasta hace poco sus tracks dieron que hablar en la famosa web Edm.com colocando sus canciones en el top ranking. Super Rush, young andalusian dj/producer that is actually focused in DNB. Neurofunk style mixes with doped beats, he belongs to "The Bass Family". Recently his songs gave a lot to talk at the famous web Edm.com positioning them at the top.PRIZE ',
    socialLinks: {
      instagram: 'https://www.instagram.com/superrushdnb/',
      soundcloud: 'https://soundcloud.com/superrush',
    },
  },
  Suspect: {
    name: 'Suspect',
    image: suspect,
    bio: 'Mario Cotorruelo, aka Suspect es un productor y DJ sevillano. Entró en el mundo del Drum & Bass en 2013 y desde entonces dedica su tiempo libre a la producción de Drum and Bass, más específicamente neurofunk, pero sin fijarse en un sólo subgenero. Todas sus creciones tienen en común un elemento, el ritmo y el groove del funk. Desde que produce ha sacado en sellos internacionales como Raving Panda Records (Alemania) y Stagma Records (Irlanda). Sus sets se caracterizan por la variedad de subgéneros, con un énfasis en el neurofunk, old school y deep/rolling. Desde 2015 lleva pinchando esporádicamente en salas pero sobre todo en raves, aunque su pasión principal es la producción musical también es un amante de los platos y de hacer bailar a las masas en directo.',
    socialLinks: {
      instagram: 'https://www.instagram.com/svspectdnb/',
      soundcloud: 'https://soundcloud.com/svspectdnb',
    },
  },
  Vandermou: {
    name: 'Vandermou',
    image: vandermou,
    bio: 'Dj/Productor de Sevilla (España). Desde muy pequeño empezó a sentirse atraido por la música electrónica observando a su tío, Interagent (Berlín), mezclar sus vinilos. Su primera experiencia fué con el grupo Diversity en 2010 dedicado al sonido Breakbeat. Tras varios años de evolución y aprendizaje decide dedicarse en exclusiva al genero Drum & Bass, creando en 2014 la promotora Jack The Ripper Events, con la que lleva más de 8 años trayendo artistas de primer nivel a su ciudad, y formando el grupo Aimlessly Team. Pero es en Mayo de 2016 cuando decide iniciar su carrera en solitario bajo el nombre de Vandermou. Desde esa fecha hasta la actualidad, ha conseguido hacerse un hueco en la escena de la ciudad, actuando en la gran mayoría de clubs y grandes eventos del estilo (Dub Elements & Friends). Eso le ha dado oportunidad para actuar también en otras ciudades españolas (Barcelona, Malaga, Granada, Jaen, Cadiz, Huelva y Córdoba) y en paises como Croacia (Outlook Festival 2016 y 2017), Republica Checa (Cross Club) y Reino Unido. Sus sets destacan por la rapidez y contundencia, pudiendo escuchar la gran mayoría de los estilos de Neurofunk. En cuanto a producción, busca esa misma variedad creando tracks de estilos Neurofunk y Deep. En Junio de 2017 sacó su primer EP a la venta por el sello Nagual Recordings y actualmente cuenta con otras referencias en sellos como FMR, Black Monsta Records, Histeria Records, Nu Error Audio, Wild Ravers Records, Melting Pot Records, Gutting Audio, Suspect Device Music, Wharf Recordings, Drumad y Space Pirate Recordings. En Marzo de 2020 creó su último proyecto, Gutting Audio. Un nuevo sello discográfico dedicado a los sonidos neurofunk.',
    socialLinks: {
      instagram: 'https://www.instagram.com/vandermou.dnb/',
      soundcloud: 'https://soundcloud.com/vandermou',
    },
  },
}
