import h from './img/h.png'
import i from './img/i.png'

export const events = [
  {
    id: '1',
    title: 'GUTTING AUDIO NIGHT: IMPEX',
    date: '24/03',
    image: h,
    url: 'https://www.monsterticket.com/evento/lemon-jueves-05-diciembre',
  },
  {
    id: '2',
    title: 'GUTTING AUDIO NIGHT: MV',
    date: '24/07',
    image: i,
    url: 'https://www.monsterticket.com/evento/lemon-jueves-05-diciembre',
  }
  


  
]

